import { navigate } from 'gatsby';
import { useState } from 'react';

import { preRegister } from '@api/authService';
import { useSuspensePromise } from '@hooks/useSuspensePromise';
import { ErrorResponse } from '@pb/api/error_pb';

export const usePreSignup = () => {
  const [error, setError] = useState<string>();
  const handler = useSuspensePromise(
    async (email: string, password: string, passwordConfirm: string) => {
      if (password !== passwordConfirm) {
        setError('パスワードと確認用パスワードが一致していません');
        return;
      }

      await preRegister({ email, password })
        .then(() => {
          navigate('/pre-signup/complete');
        })
        .catch((e: ErrorResponse) => {
          setError(e.getError());
        });
    }
  );

  return { handler, error };
};
