import { useState } from 'react';

import { ErrorResponse } from '@pb/api/error_pb';

// 多重fetch対策にsuspenseを自前実装したフック。いい解決策が出たら置き換える
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSuspensePromise = <Args extends [...any] = [], E = ErrorResponse>(
  cb: (...a: Args) => Promise<void>,
  onError: (e: E) => void = (e) => {
    throw e;
  }
) => {
  const [promise, setPromise] = useState<Promise<void>>();

  if (promise) {
    throw promise;
  }

  return (...args: Args) => {
    setPromise(
      cb(...args)
        .catch(onError)
        .finally(() => {
          setPromise(undefined);
        })
    );
  };
};
