import styled from 'styled-components';

export const SignupWrapper = styled.div`
  width: 480px;
  height: 700px;
  padding: 90px 55px;
  margin: 0 auto;
  border-radius: 25px;
  background: #fdf6e8;
`;

export const SignupContent = styled.div`
  width: 265px;
  height: 270px;
  margin: 0 auto;
`;

export const Title = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 40px;
`;

export const Text1 = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  margin: 20px 0;
`;

export const Text2 = styled.div`
  border-bottom: 1px solid black;
  :hover {
    opacity: 0.6;
  }
  cursor: pointer;
`;

export const Text3 = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;

export const InputGroup = styled.div`
  div {
    margin: 5px 0;
    input {
      border-radius: 0;
    }
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  width: 210px;
  margin: 30px auto;
  button {
    border-radius: 30px;
  }
`;

export const Bar = styled.div`
  border-bottom: 1px solid #bababa;
  margin: 30px 0;
`;

export const SNSSignupGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 120px;
`;

export const ErrorMessage = styled.div`
  margin: 30px 0;
  color: red;
  text-align: center;
`;
