import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { Description, title } from '@components/layout/SEO';
import { Center } from '@components/layout/center';
import { PreSignup } from '@features/signup/PreSignup';

const PreSignupPage = () => (
  <Layout>
    <Helmet>
      <title>新規会員登録 | {title}</title>
      {Description}
    </Helmet>
    <Suspense fallback={<Center>Loading...</Center>}>
      <PreSignup />
    </Suspense>
  </Layout>
);

export default PreSignupPage;
