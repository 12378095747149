import React, { ComponentProps, FC, useState } from 'react';

import { usePreSignup } from '../hooks/usePreSignup';

import * as Style from './style';

import { Input } from '@components/controls/Input';
import { Button } from '@components/controls/button';
import { Block } from '@components/layout/block';
import { Card } from '@components/layout/card';
import { VStack } from '@components/layout/vstack';
import { ErrorMessage } from '@components/typographies/error';
import { Heading } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';

export const PreSignup: FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [willReceiveEmail, setWilReceiveEmail] = useState(false);
  const preSignup = usePreSignup();

  const onSubmit: ComponentProps<'form'>['onSubmit'] = (e) => {
    e.preventDefault();
    // if (data.password !== data.confirmPassword) {
    //  return;
    // }
    preSignup.handler(email, password, passwordConfirm);
    // .catch((err: ErrorResponse) => methods.setError('email', { message: err.getError() }));
  };

  const onChangeEmail: ComponentProps<'input'>['onChange'] = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const onChangePassword: ComponentProps<'input'>['onChange'] = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };
  const onChangeConfirm: ComponentProps<'input'>['onChange'] = (e) => {
    e.preventDefault();
    setPasswordConfirm(e.target.value);
  };
  const onChangeWillReceive: ComponentProps<'input'>['onChange'] = (e) => {
    setWilReceiveEmail(e.target.checked);
  };

  return (
    <Block padding="10px">
      <Card
        width="calc(100vw - 30px)"
        md={{ width: '628px', paddingInline: '177px' }}
        height="max-content"
        bg="skin"
        mx="auto"
        px="40px"
        py="86px"
      >
        <VStack spacing="15px" as="form" onSubmit={onSubmit}>
          <Heading fontSize="24px" mb="15px">
            新規会員登録
          </Heading>
          <Heading as="h2">メールアドレスで登録</Heading>
          <VStack width="100%" spacing="10px">
            <Input
              width="100%"
              height="35px"
              placeholder="メールアドレス"
              value={email}
              onChange={onChangeEmail}
            />
            <Input
              width="100%"
              height="35px"
              placeholder="パスワード"
              type="password"
              value={password}
              onChange={onChangePassword}
            />
            <Input
              width="100%"
              height="35px"
              placeholder="確認用パスワード"
              type="password"
              value={passwordConfirm}
              onChange={onChangeConfirm}
            />
            <Paragraph>
              大文字、小文字、アルファベット、数字を含んだ８文字以上のパスワードを入力してください
            </Paragraph>
            {/* <HStack align="flex-start" spacing="4px">
              <CheckBox
                id="willReceiveEmail"
                checked={willReceiveEmail}
                onChange={onChangeWillReceive}
                placeholder=""
              />
              <Block height="max-content">
                <Paragraph fontSize="12px">
                  Tastyからの最新スイーツ情報やお得なキャンペーン情報をメルマガで受け取る
                </Paragraph>
                <Paragraph fontSize="12px">(会員限定の記念日クーポンを受け取るにはチェックしてください)</Paragraph>
              </Block>
            </HStack> */}
          </VStack>
          {preSignup.error && <ErrorMessage>{preSignup.error}</ErrorMessage>}
          <Button width="100%" height="35px" bg="orange" color="white" type="submit">
            新規会員登録
          </Button>
        </VStack>
        <Style.Bar />
        {/* <VStack spacing="9px">
          <Heading as="h2">SNSアカウントで会員登録</Heading>
          <button type="button">
            <GoogleLoginIcon />
          </button>
          <button type="button">
            <TwitterLoginIcon />
          </button>
          <button type="button">
            <FacebookLoginIcon />
          </button>
        </VStack> */}
      </Card>
    </Block>
  );
};
